import React from "react";
import Navbar from "../Navbar/Navbar";

export default function Layout({ children }) {
  return (
    <div className="bg-white flex flex-col">
      <div className="fixed w-full">
        <Navbar />
      </div>
      <div className="flex-grow mt-16">{children}</div>
    </div>
  );
}
