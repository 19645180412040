import React from "react";
import PropTypes from "prop-types";
import loadingGif from "./loadingGif.gif";

const LoadingSpinner = ({ message }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <img src={loadingGif} alt="Loading..." className="w-16 h-16" />
      {message && <p className="mt-2 text-gray-500">{message}</p>}
    </div>
  );
};

LoadingSpinner.propTypes = {
  message: PropTypes.string,
};

export default LoadingSpinner;
