import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import { routes } from './routes/routes';
// import { AUTH_V3 } from "./config/constants";
// import { useApiCall } from "./apiClient/apiClient";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';

const App = ({ orgDetails }) => {
  const {
    isAuthenticated,
    user,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
    isLoading,
    error,
    logout,
  } = useAuth0();

  const [tokens, setTokens] = useState({ xAccess: null, xIdAccess: null });
  const fetchTokens = async () => {
    await getAccessTokenSilently()
      .then((token) => {
        if (token.length > 0) {
          localStorage.setItem('accessToken', token);
          setTokens({ ...tokens, xAccess: token });
        }
      })
      .catch((err) => {
        loginWithRedirect({
          appState: {
            returnTo: `${window.location.origin}`,
          },
        });
        console.log(err);
      });
    await getIdTokenClaims()
      .then((claims) => {
        if (claims.__raw.length > 0) {
          setTokens({ ...tokens, xIdAccess: claims.__raw });
          localStorage.setItem('idToken', claims.__raw);
        }
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  useEffect(() => {
    // if (orgDetails?.orgId) {
    fetchTokens();
    // }
  }, []);

  if (isLoading) {
    return (
      <div className='flex te flex-col justify-center items-center h-screen w-full'>
        <div className='w-64'>
          <LoadingSpinner />
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className='flex te flex-col justify-center  items-center h-screen w-full'>
        <p className='mb-4'>Ops! Something went wrong.</p>
        <Button
          onClick={() => window.location.reload()}
          variant='outlined'
          className=''
        >
          Retry Now
        </Button>
      </div>
    );
  }
  if (isAuthenticated && !isLoading && !error) {
    return (
      <Layout>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          theme='light'
        />
        <Routes>
          {routes.map((item) => (
            <Route
              key={item?.key}
              path={item?.route}
              element={item?.component}
            />
          ))}
          <Route path='/' element={<Navigate to='/dashboard' replace />} />
        </Routes>
      </Layout>
    );
  }
};

export default App;
