import React, { useState } from 'react';
import { Button } from '../Button/Button';
import { useAuth0 } from '@auth0/auth0-react';

const Navbar = () => {
  const { logout } = useAuth0();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleButtonClick = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    setIsPopupOpen(false);
  };
  return (
    <div className='w-full h-[65.13px] bg-white flex items-center justify-between px-6 shadow-lg'>
      <div className='flex items-center'>
        <div className='flex items-center space-x-2'>
          <span className='font-semibold text-lg'>Project R</span>
        </div>
      </div>

      <div className='flex items-center space-x-6'>
        <Button
          onClick={() => console.log('Bell icon clicked')}
          className='p-2 bg-transparent border-none'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6 text-gray-500'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0'
            />
          </svg>
        </Button>

        <div className='relative inline-block'>
          <button
            onClick={handleButtonClick}
            className='p-0 bg-transparent border-none focus:outline-none'
          >
            <img
              src='https://via.placeholder.com/40'
              alt='Profile'
              className='w-10 h-10 rounded-full object-cover'
            />
          </button>

          {isPopupOpen && (
            <div className='absolute right-0 w-40 bg-white font-figtree  border shadow-md rounded-lg p-4 z-10'>
              <button
                onClick={handleLogout}
                className='flex items-center justify-center'
              >
                Log out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
