import { createSlice } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const initialState = {
  // isLocked: true,
  tabcomponents: [
    {
      id: '1',
      label: 'Tenant Management',
      isLocked: false,
      tenantComponents: [
        { label: 'Organisation Details', isLocked: false },
        { label: 'Module Feature', isLocked: true },
        { label: 'Theme', isLocked: false },
        { label: 'SSO Configuration', isLocked: false },
        { label: 'Infra Configuration', isLocked: false },
      ],
    },
    {
      id: '2',
      label: 'Data Management',
      isLocked: true,
    },
    {
      id: '3',
      label: 'Schedulers',
      isLocked: true,
    },
  ],
};

const orgSlice = createSlice({
  name: 'orgSlice',
  initialState,
  reducers: {
    setLockState: (state, action) => {
      const { orgId } = action.payload;
      if (orgId) {
        // Unlock the tabs if orgId is available
        // state.isLocked = false;
        state.tabcomponents = action.payload.tabcomponents.map((tab) => {
          if (!tab?.id) {
            return {
              ...tab,
              tenantComponents: tab.tenantComponents.map(
                (component, index) => ({
                  ...component,
                  isLocked: index > 0 ? false : component.isLocked,
                })
              ),
            };
          }
          return tab;
        });
        state.orgId = orgId;
      }
    },
    resetOrgSlice: () => initialState,
  },
});

export const { setLockState, resetOrgSlice } = orgSlice.actions;
export default orgSlice.reducer;
