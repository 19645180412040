import React from "react";

const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const CreateOrg = React.lazy(() => import("../pages/CreateOrg/CreateOrg"));
const ManageAdmins = React.lazy(() =>
  import("../pages/ManageAdmins/ManageAdmins")
);

export const routes = [
  {
    key: "DashboardTab",
    name: "DashboardTab",
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    key: "CreateOrgTab",
    name: "CreateOrgTab",
    route: "/create-org",
    component: <CreateOrg />,
  },
  {
    key: "EditOrgTab",
    name: "EditOrgTab",
    route: "/edit-org/:orgId",
    component: <CreateOrg />,
  },
  {
    key: "ManageAdmins",
    name: "ManageAdmins",
    route: "/manage-admins",
    component: <ManageAdmins />,
  },
];
