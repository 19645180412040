import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client'; // Correct import
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { useApiCall } from './apiClient/apiClient';
import App from './App';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { API_URL, MONK_V3 } from './config/constants';
import './index.css';
import store from './redux/store';

function RootComponent() {
  const [organization, setOrganization] = useState(null);
  const [orgDetailsError, setOrgDetailsError] = useState(null);
  const { callApi, loading, error } = useApiCall();
  const [isLoading, setLoading] = useState(true);

  const fetchOrgDetails = async (subdomain) => {
    setLoading(true);
    try {
      const res = await callApi(
        'GET',
        `${API_URL}/${MONK_V3}/get-org-details-by-subdomain?subdomain=${subdomain}`
      );
      if (res?.data?.orgId) {
        localStorage.setItem('orgDetails', JSON.stringify(res?.data));
        setOrganization(res?.data);
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (err) {
      setOrgDetailsError(
        'Error fetching organization details. Please check your organization name and try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const url = window.location.hostname;
  //   const subdomain = url.split('.')[0];
  //   const storedOrgDetails = localStorage.getItem('orgDetails');
  //   const storedSubdomain = JSON.parse(storedOrgDetails);
  //   if (storedSubdomain?.subdomain === storedSubdomain) {
  //     setOrganization(storedSubdomain);
  //     setLoading(false);
  //   } else {
  //     // fetchOrgDetails(storedSubdomain);
  //   }
  // }, []);

  // if (orgDetailsError) {
  //   return (
  //     <div className='flex flex-col justify-center items-center h-screen w-full'>
  //       <div>Error: {orgDetailsError}</div>
  //     </div>
  //   );
  // }
  // if (isLoading) {
  //   return (
  //     <div className='flex flex-col justify-center items-center h-screen w-screen'>
  //       <div className='w-64'>
  //         <p className='text-md'> Loading...</p>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Auth0ProviderWithNavigate orgDetails={organization}>
            <App orgDetails={organization} />
          </Auth0ProviderWithNavigate>
        </Suspense>
      </Router>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));

if (window.self === window.top) {
  root.render(<RootComponent />);
} else {
  root.render(<UnsecuredPage />);
}
