import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import apiClient from "../../apiClient/apiClient";

// export const fetchDashboardData = createAsyncThunk(
//   "dashboard/fetchDashboardData",
//   async () => {
//     const response = await apiClient.get("/dashboard");
//     return response.data;
//   }
// );

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    stats: {
      tenants: 0,
      subscriptions: 0,
      licenses: 0,
      activeUsers: 0,
    },
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchDashboardData.pending, (state) => {
  //       state.status = "loading";
  //     })
  //     .addCase(fetchDashboardData.fulfilled, (state, action) => {
  //       state.status = "succeeded";
  //       state.stats = action.payload.stats;
  //       state.data = action.payload.data;
  //     })
  //     .addCase(fetchDashboardData.rejected, (state, action) => {
  //       state.status = "failed";
  //       state.error = action.error.message;
  //     });
  // },
});

export default dashboardSlice.reducer;
