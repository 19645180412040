import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./slices/dashboardSlice";
import orgSlice from "./slices/orgSlice";

const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    orgSlice: orgSlice,
  },
});

export default store;
