import axios from 'axios';
import { useState, useCallback } from 'react';

// Create an axios instance with default configurations
// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   timeout: 10000,
//   headers: {
//     'Content-Type': 'application/json',
//     'x-access-token': localStorage.getItem('accessToken'),
//     'x-id-token': localStorage.getItem('idToken'),
//   },
// });
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    'x-access-token': localStorage.getItem('accessToken'),
    'x-id-token': localStorage.getItem('idToken'),
  },
});

// API wrapper function with retry logic
const apiCall = async (
  method,
  url,
  data = null,
  params = null,
  retries = 3,
  type
) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    if (type === 'media') {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + '/' + url,
          {
            method: method,
            headers: {
              'Content-Type': `application/json`,
              'x-access-token': localStorage.getItem('accessToken'),
              'x-id-token': localStorage.getItem('idToken'),
            },
            body: data && method === 'POST' ? JSON.stringify(data) : undefined,
          }
        );
        return response;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.warn('Unauthorized access - logging out');
          return;
        }

        if (attempt === retries) {
          console.error('API call error after multiple attempts:', error);
          throw error; // Rethrow error after final attempt
        }
        console.warn(
          `API call failed, retrying attempt ${attempt} of ${retries}...`
        );
      }
    } else {
      try {
        const headers = {
          'Content-Type':
            type === 'multipart/form-data'
              ? 'multipart/form-data'
              : 'application/json', // Default to 'application/json'
          'x-access-token': localStorage.getItem('accessToken'),
          'x-id-token': localStorage.getItem('idToken'),
        };
        const response = await axiosInstance({
          method,
          url,
          data,
          params,
          headers,
        });
        return response.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.warn('Unauthorized access - logging out');
          return;
        }

        if (attempt === retries) {
          console.error('API call error after multiple attempts:', error);
          throw error; // Rethrow error after final attempt
        }
        console.warn(
          `API call failed, retrying attempt ${attempt} of ${retries}...`
        );
      }
    }
  }
};

export const useApiCall = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const callApi = useCallback(
    async (method, url, data = {}, params = null, type) => {
      setLoading(true);
      setError(null);
      try {
        const result = await apiCall(method, url, data, params, 3, type);
        setLoading(false);
        return result;
      } catch (err) {
        setError(err.message);
        setLoading(false);
        throw err;
      }
    },
    []
  );

  return { callApi, loading, error };
};
